import './bootstrap';


import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();


window.STRIPE_FONTS = [
	{cssSrc: 'https://fonts.googleapis.com/css2?family=Fredoka+One&family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap'},
];
window.STRIPE_APPEARANCE =  {
	theme: 'stripe',

	variables: {
		colorPrimary: '#F5AF1C',
		colorBackground: '#FFFFFE',
		colorText: '#1D1D1B',
		colorDanger: '#DF2E38',
		fontFamily: 'Lato, system-ui, sans-serif',
		spacingUnit: '2px',
		borderRadius: '10px',
	},

	rules: {
		'.Tab': {
			border: '1px solid var(--colorPrimary)',
			boxShadow: 'none',
			fontSize: '1.1rem',
			fontFamily: '"Fredoka One", cursive',
			fontWeight: '400',
			marginRight: '20px',
			marginBottom: '20px',
		},

		'.Tab:hover': {
			borderColor: 'var(--colorPrimary)',
			color: 'var(--colorText)',
		},
		'.Tab--selected': {
			borderColor: 'var(--colorPrimary)',
			backgroundColor: 'var(--colorPrimary)',
			boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
			color: 'var(--colorBackground)',
		},
		'.Tab--selected:hover': {
			color: 'var(--colorBackground)',
		},
		'.TabLabel': {
			paddingTop: '10px',
		},
		'.TabIcon--selected': {
			fill: 'var(--colorBackground)',
		},

		'.Block': {
			border: '1px solid var(--colorPrimary)',
		},

		'.Label': {
			fontSize: '1.1rem',
			fontFamily: '"Fredoka One", cursive',
			marginTop: '20px',
			marginBottom: '5px',
		},

		'.Input': {
			border: '1px solid #D0D1CF',
			outline: 'solid 1px transparent',
			fontSize: '1rem',
			lineHeight: '2.5rem',
			padding: '0 10px',
			color: '#555555',
			transition: 'outline-color 0.3s, border-color 0.3s',
		},
		'.Input:hover': {
			borderColor: 'var(--colorPrimary)',
			outline: 'solid 1px var(--colorPrimary)',
			fontSize: '1rem',
			lineHeight: '2.5rem',
		},
		'.Input--invalid': {
			boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
		},
	}
};



import app from './components/App.vue';
import * as Sentry from "@sentry/vue";


Sentry.init({
	app,
	dsn: "https://1347a260f2c00cc69d9b5d48b7863cb3@o1069506.ingest.us.sentry.io/4508046884012032",
	release: '1.0',
	integrations: [],
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
});


app.mount('#app');



document.addEventListener('DOMContentLoaded', function (event) {
    document.getElementsByTagName('body')[0].classList.remove('no-js');
});

document.addEventListener('dblclick', function(event) {
    event.preventDefault();
    event.stopPropagation();
});

document.addEventListener('click', function(event) {
	if (event.detail > 1) {
		event.preventDefault();
		event.stopPropagation();
	}
});
