<template>
    <section class="enquiry_form mod-luggage"
            :class="{'is-loading': is_loading}">

        <EnquiryProgress />


        <EnquiryHeader title="Are you bringing luggage?">
            <p>
				Please let us know how much luggage you’ll be travelling with so we can ensure we quote on a vehicle with enough space.
            </p>
        </EnquiryHeader>


        <form class="form mod-labels_left mod-large_labels"
			  @submit.stop.prevent="submit()">

			<div class="form-field mod-toggle enquiry_form-luggage-switch">
				<label>Do you have any luggage?</label>
				<ToggleField v-model="has_luggage"
							 on-label="Yes"
							 off-label="No"
				/>
			</div>

			<div v-show="has_luggage">
				<div v-for="(luggage_item, i) in luggage"
					 class="form-field mod-select mod-narrow"
					 :class="{'is-invalid': errors.luggage && errors.luggage[i] }">

					<label :for="'luggage_'+i">
						<span v-if="luggage_item.type === 'small'">
							<Icon name="luggage-small" />
							Small day bags
							<em>(e.g. small rucksack, handbag, or laptop case)</em>
						</span>
						<span v-else-if="luggage_item.type === 'medium'">
							<Icon name="luggage-medium" />
							Medium Cases
							<em>(e.g. hand luggage or cabin bag, up to 10kg)</em>
						</span>
						<span v-else-if="luggage_item.type === 'large'">
							<Icon name="luggage-large" />
							Large Cases
							<em>(e.g. suitcase or rucksack, up to 23kg)</em>
						</span>
						<span v-else-if="luggage_item.type === 'other' && i < 4">
							<Icon name="luggage-other" />
							Other Items
							<em>(e.g. bikes and prams etc)</em>
						</span>
					</label>

					<div class="form-field-control">
						<button type="button"
								v-if="i >= 4"
								class="button mod-close"
								@click="removeOtherLuggage(luggage_item)"
								title="Remove this luggage">
						</button>

						<select v-if="luggage_item.type === 'other'"
							   v-model="luggage_item.option_id">
							<option value="">
								{{ luggage_item.option_id ? 'None' : 'Please select' }}
							</option>
							<option v-for="option in luggageOptions.others.items"
									:value="option.id">
								{{ option.value}}
							</option>
						</select>

						<input :id="'luggage_'+i"
							   type="number"
							   min="0"
							   v-model="luggage_item.quantity">
					</div>

					<FieldErrors :fields="['luggage.'+i]" />
				</div>

				<div class="enquiry_form-field-actions">
					<button type="button"
							class="button mod-add mod-blue"
							@click="addOtherLuggage()">
						+ Add
					</button>
				</div>
			</div>


			<EnquiryActions />
        </form>

    </section>
</template>

<script>
import EnquiryProgress from "./EnquiryProgress.vue";
import EnquiryHeader from "./EnquiryHeader.vue";
import EnquiryActions from "./EnquiryActions.vue";
import FieldErrors from "./FieldErrors.vue";
import ToggleField from "./ToggleField.vue";
import Icon from "./Icon.vue";


export default {
    name: "EnquiryLuggage",
    components: {
		EnquiryProgress,
		EnquiryHeader,
		EnquiryActions,
		FieldErrors,
		ToggleField,
		Icon,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
    ],

    props: {
        enquiry: Object,
        buttonText: String,
        stepUrl: String,
        nextUrl: String,
        prevUrl: String,
        stepNum: Number,
        numSteps: Number,
		hasReachedSummary: Boolean,
		luggageOptions: Object,
		isAirport: Boolean,
    },

	provide() {
		return {
			prevUrl: this.prevUrl,
			stepNum: this.stepNum,
			numSteps: this.numSteps,
			buttonText: this.buttonText,
			hasReachedSummary: this.hasReachedSummary,
		}
	},

    data() {
		let luggage = this.enquiry.luggage ?? [];

		let types = ['small', 'medium', 'large', 'other'];

		if (this.isAirport) {
			types = ['medium', 'large', 'other'];
		}

		let has_luggage = luggage.length > 0;

		for (let i = 0; i < types.length; i++) {
			let existing = luggage.some(function(item) {
				return item.type === types[i];
			});
			if (existing) continue;

			luggage.splice(i, 0,{
				type: types[i],
				quantity: 0,
				option_id: '',
			});
		}


        return {
			luggage: luggage,
			has_luggage: has_luggage,
        }
    },

    methods: {
		addOtherLuggage() {
			this.luggage.push({
				type: 'other',
				quantity: 0,
				option_id: '',
			})
		},
		removeOtherLuggage(luggage_item) {
			let index = this.luggage.indexOf(luggage_item);
			this.luggage.splice(index, 1);
		},

        async submit() {
			this.is_loading = true;

			let data = {
				luggage: []
			};
			if (this.has_luggage) {
				data.luggage = this.luggage.filter(function (luggage) {
						// Always include if other option selected
						if (luggage.option_id) return true;
						return luggage.quantity > 0;
				});
			}

            const { response, error, errors } = (
                await this.$http(this.stepUrl, 'POST', data)
            );

            this.error = error;
            this.errors = errors;

            if (response && response.success) {
				if (this.hasReachedSummary) {
					window.location = '/enquiry/summary';
				} else {
					window.location = this.nextUrl;
				}
            }

			this.is_loading = false;
        },
    },

}
</script>
