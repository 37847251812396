<template>
	<div class="payment"
		 :class="{'is-loading': is_loading}">

		<ul class="button_list mod-stretch payment-options"
			v-if="payment_options.length > 1">
			<li class="payment-option">
				<button type="button"
						@click="selectPaymentOption('deposit')"
						:class="{'mod-faded': payment_option && payment_option !== 'deposit'}"
						class="button">
					Pay my deposit of {{ $filters.currency(deposit) }}
				</button>
			</li>
			<li class="payment-option">
				<button type="button"
						@click="selectPaymentOption('full')"
						:class="{'mod-faded': payment_option && payment_option !== 'full'}"
						class="button">
					Pay my full amount of {{ $filters.currency(customer_owes) }}
				</button>
			</li>
		</ul>


		<form class="form mod-labels_left mod-large_labels"
			  @submit.stop.prevent="submit()"
			  v-show="payment_option">

			<hr class="separator">

			<section class="payment-details">

				<h3>Payment summary</h3>
				<p>
					<em>{{ customer_price_note }}</em>
				</p>

				<table class="table">
					<thead>
						<tr>
							<th style="width: 50%">
								Reference
							</th>
							<td># {{ job_id }}</td>
						</tr>
						<tr v-if="customer_reference">
							<th>Your Reference</th>
							<td># {{ customer_reference }}</td>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="cost in client_costs">
							<th>{{ cost.name }}</th>
							<td>{{ $filters.currency(cost.net_amount) }}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<th>Total</th>
							<td>{{ $filters.currency(price) }}</td>
						</tr>
						<tr>
							<th>Deposit</th>
							<td>{{ $filters.currency(deposit) }}</td>
						</tr>
					</tfoot>
				</table>


				<hr class="separator">


				<div class="form-field"
					v-if="payment_option === 'full'">
					<label for="payment_amount_full">Amount Due</label>
					<div class="form-field-control mod-has_prefix">
						<span class="form-field-control-prefix">&pound;</span>
						<input type="number"
							   step="0.01"
							   min="1"
							   :max="customer_owes"
							   pattern="^\d*(\.\d{0,2})?$"
							   id="payment_amount_full"
							   v-model="payment_amount">
					</div>
				</div>

				<div class="form-field"
					v-if="payment_option === 'deposit'">
					<label for="payment_amount_deposit">Deposit Amount Due</label>
					<div class="form-field-control mod-has_prefix">
						<span class="form-field-control-prefix">&pound;</span>
						<input type="text"
							   readonly
							   id="payment_amount_deposit"
							   v-model="payment_amount">
					</div>
				</div>
			</section>


			<hr class="separator">


			<section class="payment-billing">

				<h3>Billing address</h3>

				<div class="form-field"
					 :class="{'is-invalid': errors?.name }">
					<label for="last_name">Name</label>
					<div class="form-field-control">
						<input id="last_name"
							   type="text"
							   required
							   v-model="billing_details.name">
					</div>
					<FieldErrors :fields="['last_name']" />
				</div>

				<div class="form-field"
					 :class="{'is-invalid': errors?.billing_line1 }">
					<label for="billing_line1">Address line 1</label>
					<div class="form-field-control">
						<input id="billing_line1"
							   type="text"
							   required
							   v-model="billing_details.address.line1">
					</div>
					<FieldErrors :fields="['billing_line1']" />
				</div>

				<div class="form-field"
					 :class="{'is-invalid': errors?.billing_line2 }">
					<label for="billing_line2">Address line 2</label>
					<div class="form-field-control">
						<input id="billing_line2"
							   type="text"
							   v-model="billing_details.address.line2">
					</div>
					<FieldErrors :fields="['billing_line2']" />
				</div>

				<div class="form-field"
					 :class="{'is-invalid': errors?.billing_city }">
					<label for="billing_city">City/Town</label>
					<div class="form-field-control">
						<input id="billing_city"
							   type="text"
							   required
							   v-model="billing_details.address.city">
					</div>
					<FieldErrors :fields="['billing_city']" />
				</div>

				<div class="form-field"
					 :class="{'is-invalid': errors?.billing_state }">
					<label for="billing_state">County</label>
					<div class="form-field-control">
						<input id="billing_state"
							   type="text"
							   required
							   v-model="billing_details.address.state">
					</div>
					<FieldErrors :fields="['billing_state']" />
				</div>

				<div class="form-field"
					 :class="{'is-invalid': errors?.postal_code }">
					<label for="postal_code">Postcode</label>
					<div class="form-field-control">
						<input id="postal_code"
							   type="text"
							   required
							   v-model="billing_details.address.postal_code">
					</div>
					<FieldErrors :fields="['postal_code']" />
				</div>
			</section>


			<hr class="separator">


			<section class="payment-methods">
				<h3 v-if="payment_methods.length > 0">
					Payment method
				</h3>
				<ul v-if="payment_methods.length > 0"
					class="payment-methods-existing">
					<li v-for="method in payment_methods">
						<label>
							<input type="radio"
							       name="payment_method"
							       v-model="existing_method"
							       :value="method">
							<span class="payment-methods-method">
								<strong class="payment-methods-method-brand">
									{{ method.card_brand }}
								</strong>
								<code class="payment-methods-method-number">
									**** **** **** {{ method.card_last4 }}
								</code>
								<span class="payment-methods-method-expiry">
									{{ method.card_month }}/{{ method.card_year }}
								</span>
							</span>
						</label>
					</li>
					<li>
						<label>
							<input type="radio"
							       name="payment_method"
							       v-model="existing_method"
							       :value="null">
							<span class="payment-methods-method mod-new">
								<strong class="payment-methods-method-brand">
									New Payment Method
								</strong>
							</span>
						</label>
					</li>
				</ul>

				<div
					class="form-field mod-payment"
					 :class="{'is-invalid': errors?.payment_method }">
					<div ref="paymentElements"
					     v-show="existing_method === null">
					</div>

					<FieldErrors :fields="['payment_method']" />
				</div>

				<div class="form-field mod-checkbox"
				     v-show="existing_method === null && is_authed && payment_method_type === 'card'">
					<div class="form-field-control">
						<label>
							<input type="checkbox"
							       :value="true"
							       v-model="save_payment_method">
							Save this payment method for future payments.
						</label>
					</div>
					<FieldErrors :fields="['terms']" />
				</div>

			</section>


			<hr class="separator">


			<div class="payment-fee-warning"
				v-show="fee_details.percent > 0">
				<p>
					A fee of {{ fee_details.percent }}% is
					chargeable as this is a {{ fee_details.reason }}.
				</p>
				<p>
					This will add {{ $filters.currency(fee_amount) }} to the payment.
				</p>

				<hr class="separator">
			</div>


			<button type="submit"
					class="button mod-large mod-wide mod-centered">
				Make Payment
			</button>

			<LoadingIcon v-show="is_loading" />
		</form>

	</div>
</template>


<script>
import FieldErrors from "./FieldErrors.vue";
import LoadingIcon from "./LoadingIcon.vue";
import {loadStripe} from "@stripe/stripe-js/pure";

export default {
    name: 'Payment',
    components: {
		FieldErrors,
		LoadingIcon,
    },

    inject: [
        'is_loading',
        'errors',
        'error',
    ],

    props: {
		job_id: String,
		job_hash_id: String,
		payment_intent_id: String,
		payment_intent_secret: String,
		payment_options: Object,
	    payment_methods: Object,
		client_costs: Object,
		customer_owes: Number,
		price: Number,
		deposit: Number,
		customer_reference: String,
		customer_price_note: String,
		complete_url: String,
		billing: Object,
	    is_authed: Boolean,
	},

	data() {
		let payment_option = null;
		if (this.payment_options.length === 1) {
			payment_option = this.payment_options[0];
		}

		return {
			stripe: null,
			elements: null,
			payment_el: null,
			payment_method: null,
			payment_method_type: null,
			existing_method: null,
			payment_option: payment_option,
			payment_amount: this.customer_owes,
			save_payment_method: false,
			fee_details: {
				percent: 0,
				reason: null
			},
			billing_details: {
				name: this.billing.name,
				address: {
					line1: this.billing.line1,
					line2: this.billing.line2,
					city: this.billing.city,
					postal_code: this.billing.postal_code,
					state: this.billing.state,
					country: this.billing.country || 'GB',
				},
			},
		}
	},


	async mounted() {
		this.stripe = await loadStripe(STRIPE_PUB_KEY, {
			betas: ['elements_enable_deferred_intent_beta_1'],
		});

		this.elements = this.stripe.elements({
			'clientSecret': this.payment_intent_secret,
			appearance: STRIPE_APPEARANCE,
			fonts: STRIPE_FONTS,
		});
		this.payment_el = this.elements.create('payment', {
			layout: 'tabs',
		});

		this.payment_el.mount(this.$refs.paymentElements);
		this.payment_el.on('change', this.elementPaymentMethodChanged);

		// Set the existing method once elements is ready, otherwise
		// elementPaymentMethodChanged will override the payment_method
		// set by the watcher
		this.payment_el.on('ready', () => {
			let default_method = this.payment_methods.find(m => m.is_default);
			if (default_method) {
				this.existing_method = default_method;
			}
		})
	},


	watch: {
		payment_method: function(new_val) {
			this.getCardFee();
		},
		existing_method: function(new_val) {
			this.payment_method = new_val;
		}
	},


	computed: {
		fee_amount: function() {
			return this.payment_amount * (this.fee_details.percent / 100);
		}
	},


    methods: {
		async elementPaymentMethodChanged(event) {
			this.error = null;
			this.payment_method_type = event.value.type;
			this.payment_method = null;

			if (event.error) {
				this.error = event.error.message;
			}

			if (!event.complete) return;

			if (this.payment_method_type === 'card') {
				this.is_loading = true;
				await this.createPaymentMethod();
				this.is_loading = false;
			} else {
				this.payment_method = this.payment_method_type;
			}
		},


		async createPaymentMethod() {
			let elements = this.elements;
			let _self = this;

			return await _self.stripe.createPaymentMethod({
					elements,
					params: {
						billing_details: _self.billing_details,
						allow_redisplay: 'always',
					}
				})
				.then(function(result) {
					if (result.error) {
						_self.error = result.error;
					} else {
						_self.payment_method = result.paymentMethod;
					}
				});
		},


	    async savePaymentMethod() {
		    const url = '/dashboard/payment-methods/add';
		    const data = {
			    payment_method_id: this.payment_method.id,
		    };
		    const { response, error, errors } = (
			    await this.$http(url, 'POST', data)
		    );

		    this.error = error;
		    this.errors = errors;
	    },


		async getCardFee() {
			this.error = null;
			this.errors = null;

			this.fee_details = {
				percent: 0,
				reason: null
			};

			if (this.payment_method_type !== 'card') return;
			if (!this.payment_method) return;

			const url = '/payments/fee';
			const data = {
				payment_method_id: this.payment_method.id,
			};
			const { response, error, errors } = (
				await this.$http(url, 'POST', data)
			);

			this.error = error;
			this.errors = errors;

			if (response && !this.error) {
				this.fee_details = response.data;
			}
		},


		async submit() {
			this.error = null;
			this.errors = null;

			if (!this.payment_method) {
				this.errors = {'payment_method': ['Please enter a payment method']};
				this.payment_el.focus();
				return;
			}

			this.is_loading = true;
			if (await this.updateIntent()) {
				if (this.existing_method) {
					await this.confirmCardPayment();
					return;
				}

				if (this.save_payment_method) {
					await this.savePaymentMethod();
				}

				await this.confirmPayment();
			}

			this.is_loading = false;
		},


	    /*
	    * Update the amount for the payment intent before confirming the payment
	    */
		async updateIntent() {
			const url = '/payments/payment-intent';
			const data = {
				payment_intent_id: this.payment_intent_id,
				job_id: this.job_id,
				amount: this.payment_amount,
				card_fee: this.fee_amount,
			};
			const { response, error, errors } = (
				await this.$http(url, 'POST', data)
			);

			this.error = error;
			this.errors = errors;

			if (!this.error) {
				return true;
			}
		},



	    /*
	    * Confirm payment for an existing/saved card
	     */
		async confirmCardPayment() {
			let _self = this;

			const {error} = await _self.stripe.confirmCardPayment(
				this.payment_intent_secret,
				{
					payment_method: _self.payment_method.id,
				}
			);

			if (!error) {
				window.location = _self.complete_url;
				return;
			}

			// This point will only be reached if there is an immediate error when
			// confirming the payment. Otherwise, your customer will be redirected to
			// your `return_url`. For some payment methods like iDEAL, your customer will
			// be redirected to an intermediate site first to authorize the payment, then
			// redirected to the `return_url`.
			if (error.type === 'card_error' || error.type === 'validation_error') {
				this.error = error.message;
			} else {
				this.error = 'An unexpected error occurred.';
			}
		},


		/*
		* Confirm payment for a new method from elements
		 */
		async confirmPayment() {
			let _self = this;
			let elements = _self.elements;

			const { error } = await this.stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: _self.complete_url,
					payment_method_data: {
						billing_details: _self.billing_details,
						allow_redisplay: 'always',
					}
				},
			});

			// This point will only be reached if there is an immediate error when
			// confirming the payment. Otherwise, your customer will be redirected to
			// your `return_url`. For some payment methods like iDEAL, your customer will
			// be redirected to an intermediate site first to authorize the payment, then
			// redirected to the `return_url`.
			if (error.type === 'card_error' || error.type === 'validation_error') {
				this.error = error.message;
			} else {
				this.error = 'An unexpected error occurred.';
			}
		},


		selectPaymentOption(option) {
			this.payment_option = option;

			if (this.payment_option === 'deposit') {
				this.payment_amount = this.deposit;
			} else {
				this.payment_amount = this.customer_owes;
			}
		},

    },
}
</script>
