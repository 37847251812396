export default {
    install: (app, options) => {
        app.config.globalProperties.$http = async (url, method, data) => {
			const csrf_token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');

            const params = {
                url: url,
                method: method,
                data: data,
				headers: {
					'X-CSRF-TOKEN': csrf_token
				}
            };

            let response = null;
            let error = null;
            let errors = {};


            await axios(params)
                .then((result) => {
                    response = result.data;

                })
                .catch((result) => {
					response = result.response;

                    if (response && response.status === 422) {
                        error = response.data.message;
						// Tidy validation errors
						if (error && error.length > 0) {
							error = error.replace('.', ' ');
							error += '.';
						}

                        errors = response.data.errors;

                    } else if (response && response.data.error) {
						error = response.data.error;
                        if (Array.isArray(error)) {
                            error = error.join('');
                        }

                    } else if (response && response.data.errors) {
						errors = response.data.errors;

					} else {
                        error = result.message;
                    }
                });

            return {response, error, errors};
        }
    }
}
